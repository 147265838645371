import { useState } from 'react';
import { IQuiIconProps, QuiBox, QuiBoxProps, QuiButton, QuiFlexBoxColumn, QuiIcon, QuiIconToken, QuiText } from '@tonicai/ui-quinine';
import styles from './PipelineFileStatistics.module.scss';
import { FileStatistics } from '@/types';
import { numberToEnglish, PiiTypeToLabel } from '@/pages/Dataset/utils';

type StatisticsMode = 'overview' | 'entities' | 'topics';

type TextWithIconProps = Readonly<{
    icon: QuiIconToken;
    weight: QuiBoxProps['weight'];
    text: QuiBoxProps['text'];
    children: React.ReactNode;
    iconColor?: IQuiIconProps['color'];
}>;

function TextWithIcon({ children, icon, weight, text, iconColor }: TextWithIconProps) {
    return (
        <QuiBox display="flex" alignItems="center" gap="sm">
            <QuiIcon color={iconColor} icon={icon} />
            <QuiBox weight={weight} text={text}>
                {children}
            </QuiBox>
        </QuiBox>
    );
}

type NumberRowProps = Readonly<{
    icon: QuiIconToken;
    amount: number | null;
    title: string;
}>;

function NumberRow({ icon, title, amount }: NumberRowProps) {
    if (amount == null) {
        return undefined;
    }
    return (
        <QuiBox display="flex" justifyContent="space-between">
            <QuiBox display="flex" alignItems="center" gap="sm">
                <QuiIcon icon={icon} />
                <QuiText>{title}</QuiText>
            </QuiBox>
            <QuiText>{numberToEnglish(amount)}</QuiText>
        </QuiBox>
    );
}

type EntityRowProps = Readonly<{
    title: string;
    amount: number;
    width: number;
    icon: QuiIconToken;
}>;

function EntityRow({ title, amount, width, icon }: EntityRowProps) {
    return (
        <QuiFlexBoxColumn gap="xs">
            <QuiBox display="flex" justifyContent="space-between" alignItems="center">
                <TextWithIcon icon={icon} weight="medium" text="text-xs">
                    {title}
                </TextWithIcon>
                <QuiBox text="text-xs">{numberToEnglish(amount)}</QuiBox>
            </QuiBox>
            <div className={styles.bar} style={{ width: `${width * 100}%` }} />
        </QuiFlexBoxColumn>
    );
}

type EntitiesProps = Readonly<{
    entities: Omit<EntityRowProps, 'width'>[];
    mode: StatisticsMode;
    type: StatisticsMode;
    setMode: (mode: StatisticsMode) => void;
}>;

function Entities({ entities, mode, type, setMode }: EntitiesProps) {
    let sortedList = entities.sort((a, b) => b.amount - a.amount);

    const maxAmount = sortedList.reduce((acc, entity) => {
        return entity.amount > acc ? entity.amount : acc;
    }, 0);

    if (mode === 'overview' && sortedList.length > 5) {
        sortedList = sortedList.slice(0, 5);
    }

    const singular = type === 'entities' ? 'Entity' : 'Topic';
    const plural = type === 'entities' ? 'Entities' : 'Topics';

    return (
        <QuiFlexBoxColumn gap="md" border="stroke-base" bg="background-base" borderRadius="md">
            {['topics', 'entities'].includes(mode) && (
                <>
                    <div>
                        <QuiButton
                            variant="minimal"
                            iconLeft="arrow-left"
                            onClick={() => {
                                setMode('overview');
                            }}
                            type="button"
                        >
                            Back to overall statistics
                        </QuiButton>
                    </div>
                    <QuiBox weight="medium" text="text-sm" padding="md" borderBottom="stroke-base">
                        {`${entities.length} ${entities.length === 1 ? singular : plural}`}
                    </QuiBox>
                </>
            )}

            {mode === 'overview' && (
                <QuiBox weight="medium" text="text-sm" padding="md" borderBottom="stroke-base">
                    {sortedList.length === 1 ? `Top ${singular}` : `Top ${sortedList.length} ${plural}`}
                </QuiBox>
            )}

            <QuiFlexBoxColumn gap="md" padding="md">
                {sortedList.map((entity) => (
                    <EntityRow key={entity.title} width={entity.amount / maxAmount} {...entity} />
                ))}

                {mode === 'overview' && entities.length > 5 && (
                    <div>
                        <QuiButton
                            iconRight="arrow-right"
                            size="sm"
                            onClick={() => {
                                setMode(type);
                            }}
                            type="button"
                        >
                            View All
                        </QuiButton>
                    </div>
                )}
            </QuiFlexBoxColumn>
        </QuiFlexBoxColumn>
    );
}

type PipelinesStatisticsProps = Readonly<{
    statistics?: FileStatistics;
}>;

export function PipelineFileStatistics({ statistics }: PipelinesStatisticsProps) {
    const [mode, setMode] = useState<StatisticsMode>('overview');

    if (statistics == null) {
        return undefined;
    }

    const entities = Object.entries(statistics.piiTypesCounts).map(([title, amount]) => ({
        title,
        amount,
        icon: (PiiTypeToLabel[title]?.icon as QuiIconToken) || ('briefcase' as QuiIconToken),
    }));

    const topics = Object.entries(statistics.topics).map(([title, amount]) => ({
        title,
        amount,
        icon: 'bookmark' as QuiIconToken,
    }));

    if (!statistics || (statistics.totalFileCount === 0 && statistics.wordCount === 0 && entities.length === 0 && topics.length === 0)) {
        return null;
    }

    return (
        <QuiFlexBoxColumn gap="md" style={{ minWidth: 230 }}>
            {mode === 'overview' && (
                <QuiFlexBoxColumn bg="background-base" border="stroke-base" borderRadius="md">
                    <QuiBox weight="medium" text="text-sm" padding="md" borderBottom="stroke-base">
                        File Statistics
                    </QuiBox>
                    <QuiFlexBoxColumn gap="xs" padding="md">
                        {mode === 'overview' && (
                            <>
                                <NumberRow title="Total # of files" icon="file" amount={statistics.totalFileCount} />
                                <NumberRow title="Total # of words" icon="type" amount={statistics.wordCount} />
                                <NumberRow title="Entities detected" icon="briefcase" amount={entities.length === 0 ? null : entities.length} />
                                <NumberRow title="Topics detected" icon="bookmark" amount={topics.length === 0 ? null : topics.length} />
                            </>
                        )}
                    </QuiFlexBoxColumn>
                </QuiFlexBoxColumn>
            )}

            {entities && entities.length > 0 && mode !== 'topics' && <Entities mode={mode} type="entities" setMode={setMode} entities={entities} />}

            {topics && topics.length > 0 && mode !== 'entities' ? <Entities mode={mode} type="topics" setMode={setMode} entities={topics} /> : null}
        </QuiFlexBoxColumn>
    );
}
