import { createContext, useContext } from 'react';
import { Dataset } from '@/types';

export type DatasetContextValue = {
    dataset: Dataset;
};

export const DatasetContext = createContext<DatasetContextValue>({} as DatasetContextValue);

export function DatasetProvider({ children, ...props }: DatasetContextValue & { children: React.ReactNode }) {
    return <DatasetContext.Provider value={props}>{children}</DatasetContext.Provider>;
}

export function useDatasetContext() {
    return useContext(DatasetContext);
}
