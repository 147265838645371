import { PiiTypeGeneratorState } from '@/types';
import { QuiBox, QuiSegmentedControl, QuiSegmentedControlButton, QuiText } from '@tonicai/ui-quinine';
import { useState } from 'react';

type PopoverContentProps = {
    setGeneratorSetup: (newState: PiiTypeGeneratorState) => void;
    currentValue: PiiTypeGeneratorState;
    label_description: string;
};

export function EntityGeneratorTypeSelection({ setGeneratorSetup, currentValue, label_description }: PopoverContentProps) {
    const [value, setValue] = useState<PiiTypeGeneratorState>(currentValue);

    const set = (v: PiiTypeGeneratorState) => {
        setValue(v);
        setGeneratorSetup(v);
    };

    return (
        <QuiBox style={{ width: 276 }} display={'flex'} flexDirection={'column'} gap={'xs'}>
            <QuiText size={'text-sm'}>{label_description}</QuiText>
            <QuiSegmentedControl onChange={set} value={value}>
                <QuiSegmentedControlButton style={{ width: 'min-content' }} iconLeft={'shuffle'} value={'Synthesis'}>
                    Synthesize
                </QuiSegmentedControlButton>
                <QuiSegmentedControlButton style={{ width: 'min-content' }} iconLeft={'eye-off'} value={'Redaction'}>
                    Redact
                </QuiSegmentedControlButton>
                <QuiSegmentedControlButton style={{ width: 'min-content' }} iconLeft={'eye'} value={'Off'}>
                    Off
                </QuiSegmentedControlButton>
            </QuiSegmentedControl>
            <QuiText size={'text-sm'} color={'text-subdued'}>
                Setting is shared across all instances of this entity type.
            </QuiText>
        </QuiBox>
    );
}
