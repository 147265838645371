import { GettingStartedPlayground } from '@/components/GettingStartedPlayground/GettingStartedPlayground';
import { Welcome } from './Welcome';
import { useEffect, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { QuiBox, QuiCell, QuiFlexBoxColumn, QuiGridLayout } from '@tonicai/ui-quinine';
import { CreateDatasetDialog } from '@/components/CreateDatasetDialog/CreateDatasetDialog';
import { CreatePipelineDialog } from '@/components/CreatePipelineDialog/CreatePipelineDialog';
import { HelpDocumentation } from '@/components/HelpDocumentation/HelpDocumentation';
import { showOnboardingSurveyAtom, openPlanDialog, useFreeTrialActive, useIsHostedProd, usageAtom, initAuth } from '@/stores';
import { FreeTrialDialog } from './FreeTrialDialog';
import { atomWithStorage } from 'jotai/utils';
import { useLocation, useNavigate } from 'react-router-dom';

const closedFreeTrialModalAtom = atomWithStorage('closedFreeTrialModal', false);

export function Home() {
    const navigate = useNavigate();
    const location = useLocation();

    const [hasClosedModal, setHasClosedModal] = useAtom(closedFreeTrialModalAtom);
    const usage = useAtomValue(usageAtom);
    const freeTrialActive = useFreeTrialActive();
    const isHostedProd = useIsHostedProd();

    const showFreeTrialDialog = isHostedProd && !hasClosedModal && freeTrialActive && usage?.wordCount === 0;

    const showOnboardingSurvey = useAtomValue(showOnboardingSurveyAtom);

    const [activeModal, setActiveModal] = useState<null | 'dataset' | 'parsejob' | 'learn-more'>(null);

    const closeModal = () => {
        setActiveModal(null);
        setHasClosedModal(true);
    };

    useEffect(() => {
        initAuth();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const ssoCallback = searchParams.get('ssoCallback');

        if (isHostedProd && showOnboardingSurvey && usage && usage.wordCount === 0) {
            navigate('/onboarding-survey');
        } else if (ssoCallback) {
            const currentPath = location.pathname;
            navigate(currentPath, { replace: true });
        }
    }, [showOnboardingSurvey, usage, navigate, location.search, location.pathname, isHostedProd]);

    return (
        <QuiGridLayout data-test="home-page" gap="md" style={{ height: 'calc(100vh - 44px - 2rem)' }}>
            <QuiCell width={3}>
                <QuiFlexBoxColumn style={{ height: '100%' }} gap="lg">
                    <Welcome />
                    <HelpDocumentation />
                </QuiFlexBoxColumn>
            </QuiCell>
            <QuiCell style={{ maxHeight: '100%' }} width={9}>
                <QuiBox style={{ paddingTop: "qui-space('md')", height: '100%' }}>
                    <GettingStartedPlayground />
                </QuiBox>
            </QuiCell>

            <CreateDatasetDialog isOpen={!showFreeTrialDialog && activeModal === 'dataset'} onClose={closeModal} />
            <CreatePipelineDialog isOpen={!showFreeTrialDialog && activeModal === 'parsejob'} onClose={closeModal} />
            <FreeTrialDialog
                isOpen={showFreeTrialDialog}
                onClose={closeModal}
                onLearnMore={() => {
                    closeModal();
                    openPlanDialog();
                }}
            />
        </QuiGridLayout>
    );
}
