import { RequestExplorerCodeSnippet } from '@/components/RequestExplorerCodeSnippet/RequestExplorerCodeSnippet';
import { QuiBox } from '@tonicai/ui-quinine';

export function RequestExplorerGettingStarted() {
    return (
        <QuiBox>
            <RequestExplorerCodeSnippet />
        </QuiBox>
    );
}
