import styles from '@/components/RequestExplorerRedactedContent/RequestExplorerSplitView.module.scss';
import { QuiBox, QuiText } from '@tonicai/ui-quinine';
import classNames from 'classnames';

type ISplitViewProps = {
    leftHtml: string;
    rightHtml?: string;
    leftTitle?: string;
    rightTitle?: string;
};

type SingleSideProps = {
    html: string;
    title?: string;
};

function SingleSide({ html, title }: SingleSideProps) {
    if (title != null) {
        return (
            <QuiBox className={styles.container} display={'flex'} flexDirection={'column'} gap={'md'}>
                <QuiBox
                    bg={'background-base'}
                    display={'flex'}
                    style={{
                        width: '100%',
                        borderRadius: 0,
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                    }}
                    border={'stroke-base'}
                    padding={'xs'}
                >
                    <QuiText weight={'bold'} size={'text-lg'}>
                        {title}
                    </QuiText>
                </QuiBox>

                <div className={classNames(styles.content, 'fs-mask')} dangerouslySetInnerHTML={{ __html: html }} />
            </QuiBox>
        );
    }
    return (
        <div className={styles.container}>
            <div className={classNames(styles.content, 'fs-mask')} dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
}

export function RequestExplorerSplitView({ leftHtml, rightHtml, leftTitle, rightTitle }: ISplitViewProps) {
    if (rightHtml == null) {
        return (
            <QuiBox className={styles.splitView} display={'flex'} style={{ height: '100%' }}>
                <SingleSide title={leftTitle} html={leftHtml} />
            </QuiBox>
        );
    }
    return (
        <QuiBox className={styles.splitView} display={'flex'} style={{ height: '100%' }}>
            <SingleSide title={leftTitle} html={leftHtml} />
            <div className={styles.separator} />
            <SingleSide title={rightTitle} html={rightHtml} />
        </QuiBox>
    );
}
