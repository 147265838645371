import { CopyToClipboardButton } from '@/components/CopyToClipboardButton/CopyToClipboardButton';
import { allowAtom, blockAtom, generatorSetupAtom } from './Playground/state';
import { usePlaygroundContext } from './Playground/usePlaygroundContext';
import { PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { LabelCustomList } from '@/types/api_request_record';
import { QuiBox, QuiButton, QuiCollapse, QuiSegmentedControl, QuiSegmentedControlButton } from '@tonicai/ui-quinine';
import { CodeBlock } from '../CodeBlock/CodeBlock';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { instrumentation } from '@/instrumentation/instrumentation';

type RequestPreviewProps = {
    content: string;
};

type Languages = 'python' | 'javascript' | 'bash';

export function SdkSnippet({ content }: RequestPreviewProps) {
    const storeOptions = usePlaygroundContext();

    const [allowList] = useAtom(allowAtom, storeOptions);
    const [blockList] = useAtom(blockAtom, storeOptions);
    const [generatorSetup] = useAtom(generatorSetupAtom, storeOptions);

    const [language, setLanguage] = useState<Languages>('python');
    const [code, setCode] = useState<string>('');

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (language === 'python') {
            setCode(generatePythonCode(content, allowList, blockList, generatorSetup));
        } else if (language === 'bash') {
            setCode(generateCurlCommand(content, allowList, blockList, generatorSetup));
        } else {
            setCode('');
        }
    }, [content, language, allowList, blockList, generatorSetup]);

    return (
        <QuiBox>
            {!isOpen && (
                <QuiBox display={'flex'} bg={'surface-neutral'} padding={'lg'} gap={'lg'}>
                    <QuiButton
                        iconLeft={'code'}
                        iconRight={'chevron-right'}
                        variant={'default'}
                        style={{ background: 'var(--qui-color-white-200)' }}
                        onClick={() => {
                            setIsOpen(true);
                            instrumentation.showPlaygroundCode();
                        }}
                    >
                        Show Code
                    </QuiButton>
                    <CopyToClipboardButton
                        style={{ background: 'var(--qui-color-white-200)' }}
                        variant="default"
                        icon="clipboard"
                        content={code}
                        onCopy={() => instrumentation.copyPlaygroundCode(language, code, isOpen)}
                    >
                        Copy
                    </CopyToClipboardButton>
                </QuiBox>
            )}
            <QuiCollapse isOpen={isOpen}>
                <QuiBox
                    border={'stroke-base'}
                    borderRadius={'xs'}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'2xs'}
                    style={{
                        background: 'var(--qui-color-white-300)',
                        flexBasis: '100%',
                        height: 200,
                    }}
                >
                    <QuiBox style={{ background: 'var(--qui-color-white-200)' }} padding={'xs'}>
                        <QuiBox display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <QuiBox display={'flex'} gap={'md'}>
                                <QuiButton
                                    style={{ background: 'var(--qui-color-white-300)' }}
                                    iconLeft={'code'}
                                    iconRight={'chevron-down'}
                                    variant={'default'}
                                    onClick={() => setIsOpen(false)}
                                >
                                    Hide Code
                                </QuiButton>

                                <QuiSegmentedControl<Languages> size={'md'} value={language} onChange={(value) => setLanguage(value)}>
                                    <QuiSegmentedControlButton style={{ background: 'var(--qui-color-white-300)' }} value={'python'}>
                                        Python
                                    </QuiSegmentedControlButton>
                                    <QuiSegmentedControlButton style={{ background: 'var(--qui-color-white-300)' }} value={'bash'}>
                                        cURL
                                    </QuiSegmentedControlButton>
                                </QuiSegmentedControl>
                            </QuiBox>
                            <CopyToClipboardButton
                                style={{ background: 'var(--qui-color-white-300)' }}
                                variant="default"
                                icon="copy"
                                content={code}
                                onCopy={() => instrumentation.copyPlaygroundCode(language, code, isOpen)}
                            >
                                Copy Code
                            </CopyToClipboardButton>
                        </QuiBox>
                    </QuiBox>
                    <QuiBox
                        className={'fs-mask'}
                        flexGrow={'1'}
                        style={{
                            overflow: 'auto',
                            background: 'var(--qui-color-white-300)',
                        }}
                        padding={'xs'}
                    >
                        <CodeBlock backgroundColor={'var(--qui-color-white-300)'} code={code} language={language} wrapLongLines={true} />
                    </QuiBox>
                </QuiBox>
            </QuiCollapse>
        </QuiBox>
    );
}

function generatePythonCode(
    content: string,
    allowList: Map<string, LabelCustomList>,
    blockList: Map<string, LabelCustomList>,
    generatorSetup: Map<PiiTypeEnum, PiiTypeGeneratorState>
): string {
    const host = window.location.protocol + '//' + window.location.host;
    const imports = "from tonic_textual.redact_api import TextualNer\n\nner = TextualNer('" + host + "', '<api_key>')\n\n";

    const safeContent = content.replaceAll("'", "\\'");

    const labelAllowList = Array.from(allowList.keys())
        .map((key) => {
            const value = allowList.get(key);
            if (value != null && value.regexes.length > 0) {
                return "'" + key + "': [" + value.regexes.map((regex) => "'" + regex.replace("'", "\\'") + "'").join(', ') + ']';
            }
            return '';
        })
        .join(', ');

    const labelBlockList = Array.from(blockList.keys())
        .map((key) => {
            const value = blockList.get(key);
            if (value != null && value.regexes.length > 0) {
                return "'" + key + "': [" + value.regexes.map((regex) => "'" + regex.replace("'", "\\'") + "'").join(', ') + ']';
            }
            return '';
        })
        .join(', ');

    const gs = Array.from(generatorSetup.keys()).map((key) => {
        const value = generatorSetup.get(key);
        if (value === 'Redaction') {
            return '';
        }
        return "'" + key + "': '" + value + "'";
    });

    const args: string[] = [];
    args.push("'" + safeContent + "'");
    if (labelAllowList.length > 0) {
        args.push('label_allow_lists={' + labelAllowList + '}');
    }

    if (labelBlockList.length > 0) {
        args.push('label_block_lists={' + labelBlockList + '}');
    }

    if (gs.length > 0) {
        args.push('generator_config={' + gs + '}');
    }

    if (args.length === 1) {
        const code = 'response = ner.redact(' + args.join(', ') + ')';
        return imports + code;
    } else {
        const code = 'response = ner.redact(\n\t' + args[0] + ',\n\t' + args.slice(1).join(',\n\t') + '\n)';
        return imports + code;
    }
}

function generateCurlCommand(
    content: string,
    allowList: Map<string, LabelCustomList>,
    blockList: Map<string, LabelCustomList>,
    generatorSetup: Map<PiiTypeEnum, PiiTypeGeneratorState>
): string {
    const host = window.location.protocol + '//' + window.location.host;
    const safeContent = content;

    const allow = {};
    const block = {};
    const gs = {};
    allowList.forEach((value: LabelCustomList, key: string) => {
        Object.assign(allow, { [key]: value });
    });

    blockList.forEach((value: LabelCustomList, key: string) => {
        Object.assign(block, { [key]: value });
    });

    generatorSetup.forEach((value: PiiTypeGeneratorState, key: PiiTypeEnum) => {
        Object.assign(gs, { [key]: value });
    });

    // eslint-disable-next-line
    const data: { [k: string]: any } = {};

    data['text'] = safeContent;

    for (const key of Object.keys(allow)) {
        // @ts-expect-error allow any type
        if (allow[key].regexes.length === 0) {
            // @ts-expect-error allow any type
            delete allow[key];
        }
    }

    if (Object.keys(allow).length > 0) {
        data['labelAllowLists'] = allow;
    }

    for (const key of Object.keys(block)) {
        // @ts-expect-error allow any type
        if (block[key].regexes.length === 0) {
            // @ts-expect-error allow any type
            delete block[key];
        }
    }

    if (Object.keys(block).length > 0) {
        data['labelBlockLists'] = block;
    }

    for (const key of Object.keys(gs)) {
        // @ts-expect-error allow any type
        if (gs[key] === 'Redaction') {
            // @ts-expect-error allow any type
            delete gs[key];
        }
    }

    if (Object.keys(gs).length > 0) {
        data['generatorConfig'] = gs;
    }

    const dataPayload = JSON.stringify(data, null, 2).replace("'", String.raw`'\''`);

    return (
        'curl -X POST "' +
        host +
        '/api/redact" \\\n-H "Content-Type: application/json" \\\n-H "Authorization: ApiKey <api_key>" \\\n-d \'' +
        dataPayload +
        "'"
    );
}
