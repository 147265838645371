import queryString from 'query-string';
import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosInstance } from 'axios';

const defaultConfig = { refetchOnWindowFocus: false };

export function useGetQuery<TResult = void, TError = void>(
    client: AxiosInstance,
    url: string,
    query?: object | string,
    config?: UseQueryOptions<TResult, TError>
) {
    const queryKey = [url, typeof query === 'object' ? queryString.stringify(query) : query].filter(Boolean).join('?');

    return useQuery<TResult, TError>(queryKey, async () => (await client.get(queryKey)).data, { ...defaultConfig, ...config });
}
