import { usePipelineContext } from '@/components/PipelineLayout/usePipelineContext';
import { FileUploadPipeline } from '@/pages/ParseJob/FileUploadPipeline';
import { QuiBox } from '@tonicai/ui-quinine';
import { AmazonS3Pipeline } from './AmazonS3Pipeline';

export function ParseJob() {
    const { parseJobConfig } = usePipelineContext();
    const pipeline = parseJobConfig.useInternalBucket ? <FileUploadPipeline /> : <AmazonS3Pipeline />;

    return <QuiBox padding="md">{pipeline}</QuiBox>;
}
