import { createLoadedAtomFromFetchStatusAtom } from '@/stores/atom-creators';
import axios from 'axios';
import { client } from '@/services/HTTPClient';
import { globalStore } from '@/stores/globalStore';
import { FetchStatus, PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { LabelCustomList, RecordedApiRequest, RecordedApiRequestDetails } from '@/types/api_request_record';
import { getLoadingFetchStatus } from '@/utils';
import { atom, useAtomValue } from 'jotai';

const ENDPOINT = '/api/recordedapirequest';
export const DefaultRequestExplorerTablePageSize = 50;

export function useApiRequests() {
    return useAtomValue(apiRequestAtom);
}

export function useSingleApiRequestDetails() {
    return useAtomValue(singleRequestAtom);
}

export function useModifyApiRequestDetails() {
    return useAtomValue(modifyRequestAtom);
}

export const apiRequestAtom = atom<RecordedApiRequest>({
    recordedApiRequests: [],
    numRecords: 0,
});
export const singleRequestAtom = atom<RecordedApiRequestDetails>();
export const modifyRequestAtom = atom<RecordedApiRequestDetails>();
export const apiRequestFetchStatusAtom = atom<FetchStatus>('init');
export const singleRequestFetchStatusAtom = atom<FetchStatus>('init');
export const modifyRequestFetchStatusAtom = atom<FetchStatus>('init');
export const apiRequestLoadedAtom = createLoadedAtomFromFetchStatusAtom(apiRequestFetchStatusAtom);
export const singleRequestLoadedAtom = createLoadedAtomFromFetchStatusAtom(singleRequestFetchStatusAtom);

let modifyRequestAbortController = new AbortController();
export async function fetchModifiedRequest(
    id: string,
    labelAllowLists: Map<string, LabelCustomList>,
    labelBlockLists: Map<string, LabelCustomList>,
    generatorSetup: Map<PiiTypeEnum, PiiTypeGeneratorState>
) {
    globalStore.set(modifyRequestFetchStatusAtom, getLoadingFetchStatus(globalStore.get(modifyRequestFetchStatusAtom)));

    modifyRequestAbortController.abort();
    modifyRequestAbortController = new AbortController();

    const allow = {};
    const block = {};
    const gs = {};
    labelAllowLists.forEach((value: LabelCustomList, key: string) => {
        Object.assign(allow, { [key]: value });
    });

    labelBlockLists.forEach((value: LabelCustomList, key: string) => {
        Object.assign(block, { [key]: value });
    });

    generatorSetup.forEach((value: PiiTypeGeneratorState, key: PiiTypeEnum) => {
        Object.assign(gs, { [key]: value });
    });

    globalStore.set(modifyRequestFetchStatusAtom, 'loading');
    client
        .post<RecordedApiRequestDetails>(
            `${ENDPOINT}/${id}`,
            {
                labelAllowLists: allow,
                labelBlockLists: block,
                generatorSetup: gs,
            },
            {
                signal: modifyRequestAbortController.signal,
            }
        )
        .then(({ data }) => {
            const generatorSetup = new Map<PiiTypeEnum, PiiTypeGeneratorState>();
            Object.entries(data.generatorSetup).map(([key, value]) => {
                generatorSetup.set(key as PiiTypeEnum, value);
            });

            data.labelBlockLists = new Map(Object.entries(data.labelBlockLists));
            data.labelAllowLists = new Map(Object.entries(data.labelAllowLists));
            data.generatorSetup = generatorSetup;

            globalStore.set(modifyRequestAtom, data);
            globalStore.set(modifyRequestFetchStatusAtom, 'success');
        })
        .catch((e) => {
            if (!axios.isCancel(e)) {
                globalStore.set(modifyRequestFetchStatusAtom, 'error');
                throw e;
            }
        });
}

export function resetModifyRequest() {
    globalStore.set(modifyRequestAtom, undefined);
    globalStore.set(modifyRequestFetchStatusAtom, 'init');
}

let fetchAbortController = new AbortController();
export async function fetchApiRecords(take?: number, skip?: number, query?: string, sortDirection?: string) {
    globalStore.set(apiRequestFetchStatusAtom, getLoadingFetchStatus(globalStore.get(apiRequestFetchStatusAtom)));

    fetchAbortController.abort();
    fetchAbortController = new AbortController();

    const params = new URLSearchParams();
    if (skip != null) {
        params.append('skip', skip?.toString());
    }
    if (query != null) {
        params.append('searchQuery', query);
    }

    if (sortDirection != null && (sortDirection == 'asc' || sortDirection == 'desc')) {
        params.append('sortDirection', sortDirection);
    }
    params.append('take', take?.toString() ?? DefaultRequestExplorerTablePageSize.toString());

    const url = ENDPOINT + (params.size > 0 ? `?${params.toString()}` : '');
    globalStore.set(apiRequestFetchStatusAtom, 'loading');
    await client
        .get<RecordedApiRequest>(url, { signal: fetchAbortController.signal })
        .then(({ data }) => {
            globalStore.set(apiRequestAtom, data);
            globalStore.set(apiRequestFetchStatusAtom, 'success');
        })
        .catch((e) => {
            if (!axios.isCancel(e)) {
                globalStore.set(apiRequestFetchStatusAtom, 'error');
                throw e;
            }
        });
}

let fetchSingleRecordAbortController = new AbortController();
export async function fetchRequestDetails(id: string) {
    globalStore.set(singleRequestFetchStatusAtom, getLoadingFetchStatus(globalStore.get(singleRequestFetchStatusAtom)));

    fetchSingleRecordAbortController.abort();
    fetchSingleRecordAbortController = new AbortController();

    globalStore.set(singleRequestFetchStatusAtom, 'loading');

    await client
        .get<RecordedApiRequestDetails>(`${ENDPOINT}/${id}`, {
            signal: fetchSingleRecordAbortController.signal,
        })
        .then(({ data }) => {
            const generatorSetup = new Map<PiiTypeEnum, PiiTypeGeneratorState>();
            Object.entries(data.generatorSetup).map(([key, value]) => {
                generatorSetup.set(key as PiiTypeEnum, value);
            });

            data.labelBlockLists = new Map(Object.entries(data.labelBlockLists));
            data.labelAllowLists = new Map(Object.entries(data.labelAllowLists));
            data.generatorSetup = generatorSetup;

            globalStore.set(singleRequestAtom, data);
            globalStore.set(singleRequestFetchStatusAtom, 'success');
        })
        .catch((e) => {
            if (!axios.isCancel(e)) {
                globalStore.set(singleRequestFetchStatusAtom, 'error');
                throw e;
            }
        });
}
