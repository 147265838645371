import { BulkRedact } from '@/components/RequestExplorerRedactedContent/EditRequest/BulkRedact';
import { GeneratorValue } from '@/components/RequestExplorerRedactedContent/EditRequest/GeneratorValue';
import { PiiTypeToLabel } from '@/pages/Dataset/utils';
import { useCustomListPIITypeCounts } from '@/stores';
import { modifyRequestFetchStatusAtom } from '@/stores/api_request';
import { PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { LabelCustomList } from '@/types/api_request_record';
import { QuiBox, QuiButton, QuiIcon, QuiText, QuiTextInput } from '@tonicai/ui-quinine';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import styles from './EditRequest.module.scss';
import { ListType, ListOfRegex } from './ListOfRegex';
import { RedactSelect } from './RedactSelect';

type EditRequestProps = {
    blockList: Map<string, LabelCustomList>;
    allowList: Map<string, LabelCustomList>;
    setAllowList: (newList: Map<string, LabelCustomList>) => void;
    setBlockList: (newList: Map<string, LabelCustomList>) => void;
    handleClearList: () => void;
    generatorSetup: Map<PiiTypeEnum, PiiTypeGeneratorState>;
    setGeneratorSetup: (newSetup: Map<PiiTypeEnum, PiiTypeGeneratorState>) => void;
    runRequest: () => void;
};

type PiiTypeOption = {
    label: string;
    value: PiiTypeEnum;
    counts: { allow: number; block: number };
};

export function EditRequest({
    blockList,
    allowList,
    setAllowList,
    setBlockList,
    handleClearList,
    generatorSetup,
    setGeneratorSetup,
    runRequest,
}: EditRequestProps) {
    const [selectedPiiType, setSelectedPiiType] = useState<PiiTypeEnum>();
    const [filteredPiiTypes, setFilteredPiiTypes] = useState<PiiTypeOption[]>([]);
    const [piiTypeFilter, setPiiTypeFilter] = useState<string>('');
    const piiTypes = useCustomListPIITypeCounts(allowList, blockList);

    useEffect(() => {
        const filteredValues = piiTypes.filter((p) => p.label.toLowerCase().includes(piiTypeFilter.toLowerCase()));
        if (selectedPiiType != null) {
            const currentPiiType = piiTypes.find((p) => p.value === selectedPiiType);
            if (currentPiiType != null && !filteredValues.includes(currentPiiType)) {
                filteredValues.push(currentPiiType);
            }
        }
        setFilteredPiiTypes(filteredValues);
    }, [piiTypes, piiTypeFilter, selectedPiiType]);

    const handleRemoveRegex = (idx: number, listType: ListType) => {
        if (selectedPiiType == null) return;

        const key = PiiTypeEnum[selectedPiiType];
        const newList = listType == ListType.Allow ? new Map<string, LabelCustomList>(allowList) : new Map<string, LabelCustomList>(blockList);

        const currentList = listType == ListType.Allow ? allowList : blockList;

        const entry = currentList.get(key) ?? { regexes: [], strings: [] };
        const newRegexList = [...entry.regexes.slice(0, idx), ...entry.regexes.slice(idx + 1)];
        newList.set(key, {
            regexes: newRegexList ?? [],
            strings: currentList.get(key)?.strings ?? [],
        });
        if (listType == ListType.Allow) {
            setAllowList(newList);
        } else {
            setBlockList(newList);
        }
    };

    const handleAddAllowRegex = (regex: string, listType: ListType) => {
        if (selectedPiiType == null) return;
        const key = PiiTypeEnum[selectedPiiType];
        const newList = listType == ListType.Allow ? new Map<string, LabelCustomList>(allowList) : new Map<string, LabelCustomList>(blockList);

        const currentList = listType == ListType.Allow ? allowList : blockList;

        const entry = currentList.get(key) ?? { regexes: [], strings: [] };
        const newRegexList = [...entry.regexes, regex];
        newList.set(key, {
            regexes: newRegexList ?? [],
            strings: currentList.get(key)?.strings ?? [],
        });

        if (listType == ListType.Allow) {
            setAllowList(newList);
        } else {
            setBlockList(newList);
        }
    };

    const handleEditRegex = (idx: number, regex: string, listType: ListType) => {
        if (selectedPiiType == null) return;

        const key = PiiTypeEnum[selectedPiiType];
        const newList = listType == ListType.Allow ? new Map<string, LabelCustomList>(allowList) : new Map<string, LabelCustomList>(blockList);

        const currentList = listType == ListType.Allow ? allowList : blockList;

        const entry = currentList.get(key);

        if (entry != null && entry.regexes.length > idx) {
            const newRegexList = [...entry.regexes.slice(0, idx), regex, ...entry.regexes.slice(idx + 1)];
            newList.set(key, {
                regexes: newRegexList ?? [],
                strings: currentList.get(key)?.strings ?? [],
            });
            if (listType == ListType.Allow) {
                setAllowList(newList);
            } else {
                setBlockList(newList);
            }
        }
    };

    const handleSelectedPiiTypeSelection = (piiType: PiiTypeEnum) => {
        if (piiType === selectedPiiType) {
            setSelectedPiiType(undefined);
        } else {
            setSelectedPiiType(piiType);
        }
    };

    const handleBulkSelect = (v: PiiTypeGeneratorState) => {
        const newList = new Map<PiiTypeEnum, PiiTypeGeneratorState>(generatorSetup);

        for (const key of newList.keys()) {
            newList.set(key, v);
        }
        setGeneratorSetup(newList);
    };

    const handleSingleGeneratorSelect = (v: PiiTypeGeneratorState, piiType: PiiTypeEnum) => {
        const key = PiiTypeEnum[piiType];
        const newList = new Map<PiiTypeEnum, PiiTypeGeneratorState>(generatorSetup);

        newList.set(key, v);
        setGeneratorSetup(newList);
    };

    const fetchStatus = useAtomValue(modifyRequestFetchStatusAtom);

    return (
        <QuiBox
            overflow={'hidden'}
            display={'flex'}
            flexDirection={'column'}
            border={'stroke-base'}
            borderRadius="md"
            style={{ width: 600, minWidth: 600, height: '100%' }}
        >
            <QuiBox
                justifyContent={'space-between'}
                borderBottom="stroke-base"
                padding="sm"
                text="mono-text-xs"
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                bg={'background-base'}
            >
                <QuiText style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} size={'text-md'}>
                    Edit Request
                </QuiText>
                <QuiBox display={'flex'} gap={'sm'}>
                    <QuiButton variant={'default'} iconLeft={'rotate-ccw'} onClick={handleClearList}>
                        Remove Changes
                    </QuiButton>
                    <QuiButton spinner={fetchStatus === 'loading'} variant={'primary'} iconLeft={'send'} onClick={runRequest}>
                        Replay
                    </QuiButton>
                </QuiBox>
            </QuiBox>
            <QuiBox bg={'background-base'} text={'mono-text-sm'} display={'flex'} flexDirection={'row'} style={{ height: '100%' }}>
                <QuiBox
                    flexGrow={'1'}
                    display={'flex'}
                    flexDirection={'column'}
                    borderRadius={'none'}
                    borderRight={'stroke-base'}
                    style={{ overflow: 'auto' }}
                >
                    <QuiBox display={'flex'} gap={'sm'} padding={'md'}>
                        <QuiTextInput
                            placeholder={'Filter by entity'}
                            value={piiTypeFilter}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPiiTypeFilter(e.target.value)}
                        />
                        <BulkRedact handleSelect={handleBulkSelect} />
                    </QuiBox>
                    {filteredPiiTypes.map((piiType) => {
                        const selectedClass = piiType.value === selectedPiiType ? styles.selectedEntityInList : '';

                        return (
                            <QuiBox
                                key={PiiTypeEnum[piiType.value]}
                                padding={'xs'}
                                className={classNames(styles.entityInList, selectedClass)}
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                            >
                                <QuiBox
                                    className={styles.entityInListText}
                                    style={{ width: '75%', overflow: 'hidden' }}
                                    display={'flex'}
                                    gap={'xs'}
                                    alignItems={'center'}
                                    // @ts-expect-error quibox doesn't know about onclick handler
                                    onClick={() => handleSelectedPiiTypeSelection(piiType.value)}
                                >
                                    <QuiIcon color={'text-brand-black-600'} icon={PiiTypeToLabel[piiType.value].icon} />
                                    <QuiText ellipsisOverflow={true} color={'text-brand-black-600'} size={'text-sm'}>
                                        {piiType.label}
                                    </QuiText>
                                </QuiBox>
                                <QuiBox display={'flex'} gap={'xs'} alignItems={'center'}>
                                    {piiType.counts.allow > 0 && <QuiIcon color={'text-positive'} icon={'check-circle'} />}
                                    {piiType.counts.block > 0 && <QuiIcon color={'text-positive'} icon={'x-circle'} />}
                                    <RedactSelect
                                        selected={generatorSetup.get(piiType.value) ?? 'Off'}
                                        handleSelect={(v) => handleSingleGeneratorSelect(v, piiType.value)}
                                    />
                                </QuiBox>
                            </QuiBox>
                        );
                    })}
                </QuiBox>
                {selectedPiiType && (
                    <QuiBox flexGrow={'1'} display={'flex'} flexDirection={'column'} style={{ minWidth: 300 }}>
                        <GeneratorValue
                            selected={generatorSetup.get(selectedPiiType) ?? 'Off'}
                            handleSelect={(v) => handleSingleGeneratorSelect(v, selectedPiiType)}
                        />
                        <ListOfRegex
                            icon={'check-circle'}
                            title={'Add to detection'}
                            regexes={selectedPiiType ? (allowList.get(PiiTypeEnum[selectedPiiType])?.regexes ?? []) : []}
                            piiType={selectedPiiType}
                            handleRemoveAllowRegex={(idx) => handleRemoveRegex(idx, ListType.Allow)}
                            handleAddRegex={(regex) => handleAddAllowRegex(regex, ListType.Allow)}
                            handleEditRegex={(idx, regex) => handleEditRegex(idx, regex, ListType.Allow)}
                        />
                        <ListOfRegex
                            icon={'x-circle'}
                            title={'Exclude from detection'}
                            regexes={selectedPiiType ? (blockList.get(PiiTypeEnum[selectedPiiType])?.regexes ?? []) : []}
                            piiType={selectedPiiType}
                            handleRemoveAllowRegex={(idx) => handleRemoveRegex(idx, ListType.Block)}
                            handleAddRegex={(regex) => handleAddAllowRegex(regex, ListType.Block)}
                            handleEditRegex={(idx, regex) => handleEditRegex(idx, regex, ListType.Block)}
                        />
                    </QuiBox>
                )}
            </QuiBox>
        </QuiBox>
    );
}
