import { TextualUseCase } from '@/types';
import { requiredString } from '@/validation';
import { QuiBox, QuiCheckbox, QuiField, QuiIcon, QuiText, QuiTextField, QuiTooltip } from '@tonicai/ui-quinine';
import { useField, useForm } from 'react-final-form';

type TextualUseCaseFieldProps = Readonly<{
    id?: string;
    name: string;
    otherFieldName: string;
    label: string;
    helperText?: string;
}>;

type UseCaseOption = {
    useCase: TextualUseCase;
    label: string;
    helpDescription?: string;
};

export function TextualUseCaseField({ id, name, otherFieldName, helperText, label }: TextualUseCaseFieldProps) {
    const useCaseField = useField<TextualUseCase>(name, {
        subscription: { value: true, touched: true, error: true },
        validate: (value) => {
            if (!value) {
                return 'You must select a use case.';
            }
            return undefined;
        },
    });

    const form = useForm();

    const handleCheckboxChange = (useCase: TextualUseCase) => {
        const isSelected = useCaseField.input.value === useCase;
        useCaseField.input.onChange(isSelected ? undefined : useCase);
        form.mutators.setFieldTouched(name);
    };

    const useCaseOptions: UseCaseOption[] = [
        {
            useCase: TextualUseCase.AIModelTraining,
            label: 'AI model training',
            helpDescription:
                'Replace PII with synthetic values to protect sensitive data but keep the data rich and statistically sound, to ensure better training for LLMs and general models.',
        },
        {
            useCase: TextualUseCase.RAGSystem,
            label: 'RAG systems',
            helpDescription:
                'Automate pipelines to redact and normalize unstructured data for AI. Optionally expose the unredacted text to approved users.',
        },
        {
            useCase: TextualUseCase.LLMPrivacyProxy,
            label: 'LLM privacy proxy',
            helpDescription: 'Redact sensitive information before using it within prompts to keep sensitive values out of the chatbot system.',
        },
        {
            useCase: TextualUseCase.LowerTestingEnvironments,
            label: 'Lower testing environments',
            helpDescription: 'Use realistic test data to accelerate data science-based development while you maintain privacy and utility.',
        },
        { useCase: TextualUseCase.Other, label: 'Other' },
    ];

    return (
        <>
            <QuiField id={id} label={label} helperText={helperText}>
                {useCaseOptions.map((option) => (
                    <QuiBox key={option.useCase} display="flex" alignItems="center" justifyContent="start">
                        <QuiCheckbox
                            label={option.label}
                            onChange={() => {
                                handleCheckboxChange(option.useCase);
                            }}
                            checked={useCaseField.input.value === option.useCase}
                        />
                        {option.helpDescription && (
                            <QuiTooltip content={option.helpDescription} placement="bottom">
                                <QuiBox padding="none none none xs">
                                    <QuiIcon icon="help" size="xl" color="text-subdued" />
                                </QuiBox>
                            </QuiTooltip>
                        )}
                    </QuiBox>
                ))}
            </QuiField>
            {useCaseField.input.value === TextualUseCase.Other && (
                <QuiBox padding="none none none 2lg">
                    <QuiTextField placeholder={'Please Specify...'} name={otherFieldName} validate={requiredString} />
                </QuiBox>
            )}
            {useCaseField.meta.touched && useCaseField.meta.error && (
                <QuiText color={'text-danger'} size={'text-xs'} id={`${id}-error`} aria-live="assertive" data-test="qui-field-error">
                    {useCaseField.meta.error}
                </QuiText>
            )}
        </>
    );
}
