import { useDatasetContext } from '@/contexts/DatasetContext';
import { fetchFilesNeedingRescan, usePIITypes } from '@/stores';
import { PiiTypeEnum } from '@/types';
import {
    QuiBox,
    QuiButton,
    QuiIcon,
    QuiIconEnum,
    QuiModalContent,
    QuiModalDialog,
    QuiSelect,
    QuiText,
    QuiTooltip,
    useQuiModal,
} from '@tonicai/ui-quinine';
import { useState } from 'react';
import EntityManagerTable from './EntityManagerTable';
import { PiiTypeToLabel } from './utils';

type RegexListFieldModalProps = Readonly<{
    initialEntityType?: string;
}>;

export function EntityManagerModal({ initialEntityType }: RegexListFieldModalProps) {
    const modal = useQuiModal();
    const { dataset } = useDatasetContext();
    const [entityType, setEntityType] = useState(initialEntityType);

    const piiTypes = usePIITypes()
        .map((piiType) => ({
            value: piiType,
            label: PiiTypeToLabel[PiiTypeEnum[piiType]].label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const closeModal = () => {
        fetchFilesNeedingRescan(dataset.id);
        setEntityType(initialEntityType);
        modal.close();
    };

    return (
        <>
            <QuiButton
                iconLeft={QuiIconEnum.Maximize2}
                size="sm"
                title="Open Entities Manager"
                style={{ minWidth: 'fit-content', width: '50%' }}
                onClick={modal.toggle}
            >
                Open Entities Manager
            </QuiButton>

            <QuiModalDialog
                disableDismissOnEscapeKeyDown={false}
                isOpen={modal.isOpen}
                onClose={closeModal}
                title={
                    <QuiBox display={'flex'} gap={'md'} alignItems="center" margin="md none">
                        <QuiText size="text-lg">Entities in {dataset.name}</QuiText>

                        <QuiTooltip
                            content={
                                'Because this list of entities is filtered, the count displayed here might differ from the count displayed on the dataset details.'
                            }
                        >
                            <QuiIcon icon="info" size="md" />
                        </QuiTooltip>
                    </QuiBox>
                }
                style={{ maxWidth: 'unset', width: '100%' }}
            >
                <QuiModalContent>
                    <QuiBox margin="md none">
                        <QuiBox gap="sm" alignItems="center" display="flex" style={{ width: '250px' }}>
                            <QuiSelect
                                isClearable={false}
                                placeholder=""
                                value={piiTypes.find((piiType) => piiType.value === entityType)}
                                onChange={(option) => {
                                    if (option) {
                                        setEntityType(option.value);
                                    }
                                }}
                                options={piiTypes}
                            />
                        </QuiBox>
                    </QuiBox>

                    <EntityManagerTable piiType={entityType} />
                </QuiModalContent>
            </QuiModalDialog>
        </>
    );
}
