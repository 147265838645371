import { apiRequestFetchStatusAtom, DefaultRequestExplorerTablePageSize, fetchApiRecords } from '@/stores/api_request';
import { RecordedApiRecord, RecordedApiRequest } from '@/types/api_request_record';
import {
    QuiBox,
    QuiButton,
    QuiFlexBoxColumn,
    QuiFlexV,
    QuiPaginationControls,
    QuiTable,
    QuiTag,
    QuiTextInput,
    useQuiTable,
} from '@tonicai/ui-quinine';
import dayjs from 'dayjs';
import { useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';

type SimpleCellProps = {
    value: string;
};

type TagCellProps = {
    values: string[];
    id: number;
};

function PreviewCell({ value }: SimpleCellProps): JSX.Element {
    return <div className={'fs-mask'}>{value}</div>;
}

function TagCell({ values, id }: TagCellProps): JSX.Element {
    return (
        <>
            {values.map((tag) => (
                <QuiTag className={'fs-mask'} variant={'brand-royal'} size={'sm'} key={id}>
                    {tag}
                </QuiTag>
            ))}
        </>
    );
}

function DateCell({ value }: SimpleCellProps): JSX.Element {
    const date = dayjs(value).toDate();
    return (
        <>
            {date.toLocaleDateString()}
            <br />
            {date.toLocaleTimeString()}
        </>
    );
}

type RequestExplorerTableProps = {
    data: RecordedApiRequest;
};

export function RequestExplorerTable({ data }: RequestExplorerTableProps) {
    const [queryString, setQueryString] = useState('');
    const trimmedQueryString = queryString.trim();
    const [previousQuery, setPreviousQuery] = useState<string>();
    const navigate = useNavigate();
    const isLoading = useAtomValue(apiRequestFetchStatusAtom) == 'loading';

    const columnHelper = createColumnHelper<RecordedApiRecord>();

    // 2. using the column helper, create the column definitions for the table
    const columnDefinitions = [
        // columnHelper.accessor is used to define columns that are directly tied to a property of the data (Person in our case)
        // see https://tanstack.com/table/v8/docs/guide/column-defs#column-def-types
        columnHelper.accessor('preview', {
            header: 'Preview',
            cell: ({ getValue }) => PreviewCell({ value: getValue() }),
            enableSorting: false,
        }),
        columnHelper.accessor('tags', {
            header: 'Tags',
            enableSorting: false,
            cell: ({ getValue, row }) => TagCell({ values: getValue(), id: row.original.id }),
            size: 150,
        }),
        columnHelper.accessor('purgeDate', {
            header: 'Purge By',
            enableSorting: true,
            cell: ({ getValue }) => DateCell({ value: getValue() }),
            size: 125,
        }),
    ];

    // 3. using the column definitions and the paginated data, create the table instance with the useQuiTable hook
    const table = useQuiTable<RecordedApiRecord>({
        data: data.recordedApiRequests,
        columns: columnDefinitions,
        manual: true,
        totalRowCount: data.numRecords,
        initialState: {
            pagination: { pageSize: DefaultRequestExplorerTablePageSize },
            sorting: [{ id: 'purgeDate', desc: true }],
        },
        onQueryChange: async (tableState) => {
            {
                await fetchApiRecords(
                    tableState.pagination.pageSize,
                    tableState.pagination.pageSize * tableState.pagination.pageIndex,
                    trimmedQueryString,
                    tableState.sorting[0]?.desc ? 'desc' : 'asc'
                );
            }
        },
    });

    const queryApiRequests = (query: string) => {
        setQueryString(query);
        setPreviousQuery(query);
        fetchApiRecords(table.getState().pagination.pageSize, 0, query);
    };

    return (
        <QuiFlexBoxColumn gap="lg">
            <QuiFlexBoxColumn gap="xs">
                <QuiBox display="flex" gap="sm" alignItems="center" flexWrap="nowrap">
                    <QuiBox flexGrow="1">
                        <QuiTextInput
                            value={queryString}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setQueryString(e.target.value);
                            }}
                            onKeyPress={(e: KeyboardEvent) => {
                                if (e.key === 'Enter') {
                                    queryApiRequests(trimmedQueryString);
                                }
                            }}
                            placeholder="Filter by text content or tag..."
                        />
                    </QuiBox>
                    {!!queryString && previousQuery === trimmedQueryString && (
                        <QuiButton onClick={() => queryApiRequests('')} variant="default">
                            Clear
                        </QuiButton>
                    )}
                    {(!queryString || previousQuery !== trimmedQueryString) && (
                        <QuiButton
                            onClick={() => queryApiRequests(trimmedQueryString)}
                            variant={'primary'}
                            disabled={previousQuery === trimmedQueryString}
                        >
                            Search
                        </QuiButton>
                    )}
                </QuiBox>
                <QuiBox color="text-subdued" text="text-xs">
                    Search for words or tags. For example, to find API records that contain the word "patient", search for "patient". To search for
                    tags, preface the query with 'tag:'.
                </QuiBox>
            </QuiFlexBoxColumn>
            <QuiBox display={'flex'} flexDirection={'column'} gap={'sm'}>
                <QuiPaginationControls table={table} itemLabel={'API Requests'} />
                <QuiTable<RecordedApiRecord>
                    table={table}
                    tableLoading={isLoading}
                    emptyContent={
                        <QuiFlexV style={{ height: '100%' }} justify={'top'}>
                            No API records found
                        </QuiFlexV>
                    }
                    rowClickable={true}
                    onRowClick={(row) => navigate(`/request-explorer/${row.original.id}`)}
                />
            </QuiBox>
        </QuiFlexBoxColumn>
    );
}
