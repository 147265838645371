import * as amplitude from '@amplitude/analytics-browser';

import { globalStore } from '../stores/globalStore';
import { authResponseAtom } from '../stores/auth';

// Ensure amplitude is enabled before using it in tracking functions (for an
// example, look at `trackPreviewFile` below)
function getAmplitude() {
    const apiKey = globalStore.get(authResponseAtom)?.amplitudeApiKey;
    if (apiKey) return amplitude;

    return null;
}

function init(apiKey: string, apiUrl: string, userId: string) {
    const logLevel = import.meta.env.DEV ? amplitude.Types.LogLevel.Debug : amplitude.Types.LogLevel.None;
    amplitude.init(apiKey, userId, {
        serverUrl: `${apiUrl}/amplitude/api2/httpapi`,
        logLevel,
        defaultTracking: true,
    });
}

function resetUser() {
    amplitude.reset();
}

function previewFile(fileId: string) {
    getAmplitude()?.track('File Preview', { fileId });
}

function uploadFiles(fileIds: string[]) {
    getAmplitude()?.track('Upload Files', { fileIds, count: fileIds.length });
}

function uploadNonTextFiles(fileNames: string[]) {
    getAmplitude()?.track('Upload Non-Text File', {
        fileNames,
        count: fileNames.length,
    });
}

function deleteFile(fileId: string) {
    getAmplitude()?.track('Delete File', { fileId });
}

function createDataset(datasetId: string) {
    getAmplitude()?.track('Create Dataset', { datasetId });
}

function addSampleFile(datasetId: string) {
    getAmplitude()?.track('Add Sample File', { datasetId });
}

function updateDataset(datasetId: string) {
    getAmplitude()?.track('Update Dataset', { datasetId });
}

function deleteDataset(datasetId: string) {
    getAmplitude()?.track('Delete Dataset', { datasetId });
}

function bulkDeleteFiles(fileIds: string[]) {
    getAmplitude()?.track('Bulk Delete Files', {
        fileIds,
        count: fileIds.length,
    });
}

function createAPIKey(keyId: string) {
    getAmplitude()?.track('Create API Key', { keyId });
}

function revokeAPIKey(keyId: string) {
    getAmplitude()?.track('Revoke API Key', { keyId });
}

function welcomePlaygroundInteraction(
    textLength: number,
    isSample: boolean,
    numNonRedactedEntities: number,
    numLabelAllowLists: number,
    numLabelBlockLists: number
) {
    getAmplitude()?.track('Welcome Playground Interaction', {
        textLength,
        isSample,
        numNonRedactedEntities,
        numLabelAllowLists,
        numLabelBlockLists,
    });
}

function showPlaygroundCode() {
    getAmplitude()?.track('Show Playground Code Interaction');
}

function copyPlaygroundCode(language: string, code: string, isCodeShown: boolean) {
    getAmplitude()?.track('Copy Playground Code Interaction', { language, code, isCodeShown });
}

export const instrumentation = {
    init,
    previewFile,
    resetUser,
    uploadFiles,
    deleteFile,
    bulkDeleteFiles,
    createDataset,
    updateDataset,
    deleteDataset,
    createAPIKey,
    revokeAPIKey,
    uploadNonTextFiles,
    addSampleFile,
    welcomePlaygroundInteraction,
    showPlaygroundCode,
    copyPlaygroundCode,
};
