import { RequestExplorerCodeSnippet } from '@/components/RequestExplorerCodeSnippet/RequestExplorerCodeSnippet';
import { RequestExplorerTable } from '@/components/RequestExplorerTable/RequestExplorerTable';
import { RequestExplorerRedactedContent } from '@/components/RequestExplorerRedactedContent/RequestExplorerRedactedContent';
import { fetchApiRecords, fetchRequestDetails, useApiRequests, useModifyApiRequestDetails, useSingleApiRequestDetails } from '@/stores/api_request';
import { QuiBox, QuiCell, QuiFlexBoxColumn, QuiGridLayout } from '@tonicai/ui-quinine';
import { RequestExplorerProvider } from './RequestExplorerProvider';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import { HelpDocumentation } from '@/components/HelpDocumentation/HelpDocumentation';
import { useHasActivePlan, useIsHostedProd } from '@/stores';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RequestExplorerGettingStarted } from './RequestExplorerGettingStarted';

type SidebarProps = {
    showSidebar: boolean;
    hasActivePlan: boolean;
    isHostedProd: boolean;
    hasAtleastOneRequest: boolean;
};

type DisplayMode = 'table' | 'details' | 'getting_started';

function Sidebar({ showSidebar, hasActivePlan, isHostedProd, hasAtleastOneRequest }: SidebarProps) {
    if (!showSidebar) {
        return undefined;
    }

    return (
        <QuiCell width={3}>
            <QuiBox display="flex" flexDirection="column" gap="lg" maxWidth="tablet">
                <QuiFlexBoxColumn style={{ minHeight: 'calc(100vh - 44px - 2rem)' }} gap="lg">
                    <PageTitle icon="target" title="Request Explorer">
                        Examine recorded API requests to understand, analyze, and monitor model efficacy and synthetic data generation quality.
                    </PageTitle>
                    {hasAtleastOneRequest && <RequestExplorerCodeSnippet />}

                    {!hasActivePlan && isHostedProd ? (
                        <QuiFlexBoxColumn gap="md">
                            <HelpDocumentation />
                        </QuiFlexBoxColumn>
                    ) : null}
                </QuiFlexBoxColumn>
            </QuiBox>
        </QuiCell>
    );
}

export function RequestExplorer() {
    const isHostedProd = useIsHostedProd();
    const hasActivePlan = useHasActivePlan();

    const params = useParams<{ requestId: string }>();
    const [displayMode, setDisplayMode] = useState<DisplayMode>('getting_started');
    const requestId = params.requestId;

    const data = useApiRequests();

    useEffect(() => {
        if (requestId != null) {
            fetchRequestDetails(requestId);
        }

        if (requestId == null) {
            if (data.recordedApiRequests.length === 0) {
                setDisplayMode('getting_started');
            } else {
                setDisplayMode('table');
            }
        } else {
            setDisplayMode('details');
        }
    }, [requestId, data]);

    useEffect(() => {
        fetchApiRecords();
    }, []);

    const request = useSingleApiRequestDetails();
    const modifiedRequest = useModifyApiRequestDetails();

    const text = request?.content ?? '';
    const entities =
        request?.nerResults
            .flatMap((f) => f)
            .map((f) => {
                return {
                    start: f.start,
                    end: f.end,
                    pythonStart: f.start,
                    pythonEnd: f.end,
                    label: f.label,
                    text: f.text,
                    score: f.score,
                    exampleRedaction: null,
                };
            }) ?? [];

    const modifiedEntities =
        modifiedRequest?.nerResults
            .flatMap((f) => f)
            .map((f) => {
                return {
                    start: f.start,
                    end: f.end,
                    pythonStart: f.start,
                    pythonEnd: f.end,
                    label: f.label,
                    text: f.text,
                    score: f.score,
                    exampleRedaction: null,
                };
            }) ?? [];

    return (
        <RequestExplorerProvider>
            <QuiBox display="flex" justifyContent="center">
                <QuiBox maxWidth={'100percent'} flexGrow="1" padding="md" display="flex" flexDirection="column" gap="lg">
                    <QuiGridLayout gap="md">
                        <Sidebar
                            isHostedProd={isHostedProd}
                            hasActivePlan={hasActivePlan}
                            hasAtleastOneRequest={data.recordedApiRequests.length > 0}
                            showSidebar={displayMode !== 'details'}
                        />
                        <QuiCell width={displayMode !== 'details' ? 9 : 12}>
                            {displayMode === 'getting_started' && <RequestExplorerGettingStarted />}
                            {displayMode === 'table' && <RequestExplorerTable data={data} />}
                            {displayMode === 'details' && (
                                <RequestExplorerRedactedContent
                                    requestId={requestId!}
                                    text={text}
                                    entities={entities}
                                    modifiedEntities={modifiedEntities}
                                    hasModified={modifiedRequest != null}
                                    rightOriginalPreviewHtml={request?.redactedHtml ?? ''}
                                    rightModifiedPreviewHtml={modifiedRequest?.redactedHtml ?? ''}
                                    labelBlockLists={request?.labelBlockLists ?? new Map()}
                                    labelAllowLists={request?.labelAllowLists ?? new Map()}
                                    originalGeneratorSetup={request?.generatorSetup ?? new Map()}
                                />
                            )}
                        </QuiCell>
                    </QuiGridLayout>
                </QuiBox>
            </QuiBox>
        </RequestExplorerProvider>
    );
}
