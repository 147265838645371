import { CustomizeNer, FineTunedRule } from '@/components/GettingStartedPlayground/CustomizeNer';
import { PiiTypeToLabel } from '@/pages/Dataset/utils';
import { EntityGeneratorTypeSelection } from './EntityGeneratorTypeSelection';
import { PiiTypeEnum, PiiTypeGeneratorState } from '@/types';
import { QuiBox, QuiButton, QuiIcon, QuiPopover, QuiSpinner, QuiText } from '@tonicai/ui-quinine';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { ReactNode, useMemo, useState } from 'react';
import { blockTextContentEmptyAtom, generatorSetupAtom, languageAtom, previewDataAtom, previewStatusAtom, updateGeneratorSetup } from './state';
import styles from './styles.module.scss';
import { usePlaygroundContext } from './usePlaygroundContext';

type PreviewWrapperProps = Readonly<{
    children: ReactNode;
    rules: FineTunedRule[];
    language: string;
}>;

//arabic, hebrew, farsi, urdu, azerbaijani
const rtlLanguages = new Set<string>(['ar', 'he', 'fa', 'ur', 'az']);

function PreviewWrapper({ children, rules, language }: PreviewWrapperProps) {
    return (
        <QuiBox display={'flex'} flexDirection={'column'}>
            <QuiBox className={styles.previewColumnTopBar} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <QuiBox display={'flex'} gap={'sm'}>
                    <QuiIcon icon={'file-text'} />
                    <QuiText>Results</QuiText>
                </QuiBox>
                <CustomizeNer rules={rules} />
            </QuiBox>
            <QuiBox
                text="mono-text-lg"
                bg="background-neutral"
                border="stroke-base"
                borderRadius="md"
                padding="lg"
                gap="md"
                display="flex"
                flexDirection="column"
                className={classNames(styles.previewWrapper, rtlLanguages.has(language) ? styles.rtl : '')}
            >
                {children}
            </QuiBox>
        </QuiBox>
    );
}

enum PreviewDisplay {
    Empty,
    Loading,
    PreviewResponse,
}

type PreviewProps = {
    rules: FineTunedRule[];
};

export function Preview({ rules }: PreviewProps) {
    const storeOptions = usePlaygroundContext();
    const generatorSetup = useAtomValue(generatorSetupAtom, storeOptions);

    const responseStatus = useAtomValue(previewStatusAtom, storeOptions);
    const previewData = useAtomValue(previewDataAtom, storeOptions);
    const blockTextContentEmpty = useAtomValue(blockTextContentEmptyAtom, storeOptions);
    const language = useAtomValue(languageAtom, storeOptions);

    const [clickedLabelId, setClickedLabelId] = useState<string | null>(null);

    const previewDisplay = useMemo<PreviewDisplay>(() => {
        if (blockTextContentEmpty) return PreviewDisplay.Empty;

        if (!previewData) return PreviewDisplay.Loading;

        return PreviewDisplay.PreviewResponse;
    }, [blockTextContentEmpty, previewData]);

    if (responseStatus === 'error') {
        return (
            <PreviewWrapper rules={rules} language={language}>
                <QuiBox display="flex" alignItems="center" gap="sm" color="text-danger">
                    <QuiIcon size="sm" icon="alert-octagon" />
                    <span>Error fetching deidentification response</span>
                </QuiBox>
            </PreviewWrapper>
        );
    }

    if (responseStatus === 'loading' || previewDisplay === PreviewDisplay.Loading) {
        return (
            <PreviewWrapper rules={rules} language={language}>
                <QuiBox display="flex" alignItems="center" gap="sm">
                    <QuiSpinner size="sm" />
                    <span>Loading preview...</span>
                </QuiBox>
            </PreviewWrapper>
        );
    }

    if (!previewData || previewDisplay === PreviewDisplay.Empty) {
        return (
            <PreviewWrapper rules={rules} language={language}>
                <QuiText size="text-md" color="text-base--disabled">
                    Results...
                </QuiText>
            </PreviewWrapper>
        );
    }

    if (typeof previewData === 'string') {
        return (
            <PreviewWrapper language={language} rules={rules}>
                {previewData}
            </PreviewWrapper>
        );
    }

    const setGeneratorState = (newState: PiiTypeGeneratorState, entityLabel?: string) => {
        if (entityLabel == null) return;
        const newGeneratorSetup = new Map(generatorSetup);
        newGeneratorSetup.set(entityLabel as PiiTypeEnum, newState);
        updateGeneratorSetup(storeOptions.store, newGeneratorSetup);
    };

    const styleClasses = {
        Off: styles.previewRedactionInOffState,
        Redaction: styles.previewRedaction,
        Synthesis: styles.previewSynthesis,
    };

    return (
        <PreviewWrapper language={language} rules={rules}>
            {previewData.map((p) => (
                <p key={p.id}>
                    {p.chunks.length === 0 && <br />}
                    {p.chunks.map((c) => {
                        if (c.label && c.score) {
                            const operation = generatorSetup.get(c.label as PiiTypeEnum) || 'Redaction';
                            const value = operation === 'Synthesis' ? c.syntheticText : operation === 'Redaction' ? c.label : c.text;

                            return (
                                <QuiPopover
                                    key={c.id}
                                    padding={'sm'}
                                    isOpen={clickedLabelId === c.id}
                                    content={
                                        <EntityGeneratorTypeSelection
                                            currentValue={generatorSetup.get(c.label as PiiTypeEnum) || 'Redaction'}
                                            setGeneratorSetup={(v) => setGeneratorState(v, c.label)}
                                            label_description={PiiTypeToLabel[c.label].description}
                                        />
                                    }
                                    variant="default"
                                    onClose={() => setClickedLabelId(null)}
                                >
                                    <QuiButton
                                        onClick={() => setClickedLabelId(c.id)}
                                        variant={'minimal'}
                                        key={c.id}
                                        className={classNames(styleClasses[operation], styles.labelButton, 'fs-mask')}
                                    >
                                        <QuiBox>
                                            <QuiIcon
                                                size={'sm'}
                                                icon={operation === 'Synthesis' ? 'shuffle' : operation === 'Redaction' ? 'eye-off' : 'eye'}
                                            />
                                        </QuiBox>
                                        &nbsp;
                                        {value}
                                    </QuiButton>
                                </QuiPopover>
                            );
                        } else {
                            return (
                                <span className={'fs-mask'} key={c.id}>
                                    {c.text}
                                </span>
                            );
                        }
                    })}
                </p>
            ))}
        </PreviewWrapper>
    );
}
