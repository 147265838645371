import { ParseJobFileParseJobs } from '@/components/ParseJobFileParseJobs/ParseJobFileParseJobs';
import { usePipelineContext } from '@/components/PipelineLayout/usePipelineContext';
import { Tabs } from '@/components/Tabs/Tabs';
import { PipelineEntityConfigForm } from '@/pages/ParseJob/PipelineEntityConfigForm';
import { fetchFileStats } from '@/stores';
import { FileStatistics } from '@/types';
import { QuiBox } from '@tonicai/ui-quinine';
import { FilesTableLocal } from '../FileParseJob/FilesTableLocal';
import { PipelineFileStatistics } from '@/components/PipelineFileStatistics/PipelineFileStatistics';
import { useEffect, useState } from 'react';

export function FileUploadPipeline() {
    const { parseJobConfig } = usePipelineContext();
    const [fileStatistics, setFileStatistics] = useState<FileStatistics>();
    useEffect(() => {
        fetchFileStats(parseJobConfig.id).then(setFileStatistics);
    }, [parseJobConfig.id]);

    return (
        <Tabs.Container defaultTab="files">
            <Tabs.TabTriggers>
                <Tabs.TabTrigger data-test="pipeline-files-tab" icon="inbox" id="files">
                    Files
                </Tabs.TabTrigger>
                {!!parseJobConfig.synthesizeFiles && (
                    <Tabs.TabTrigger data-test="generator-config" icon="eye" id="generator-config">
                        Generator Config
                    </Tabs.TabTrigger>
                )}
            </Tabs.TabTriggers>
            <Tabs.TabContent id="files">
                <QuiBox display={'flex'} gap={'lg'}>
                    <FilesTableLocal />
                    <PipelineFileStatistics statistics={fileStatistics} />
                </QuiBox>
            </Tabs.TabContent>
            <Tabs.TabContent id="runs">
                <ParseJobFileParseJobs />
            </Tabs.TabContent>
            {!!parseJobConfig.synthesizeFiles && (
                <Tabs.TabContent id="generator-config">
                    <PipelineEntityConfigForm />
                </Tabs.TabContent>
            )}
        </Tabs.Container>
    );
}
