import styles from '@/components/RequestExplorerRedactedContent/EditRequest/ListOfRegex.module.scss';
import { SmartToolTip } from '@/components/SmartTooltip/SmartTooltip';
import { modifyRequestFetchStatusAtom } from '@/stores/api_request';
import { PiiTypeEnum } from '@/types';
import { QuiBox, QuiButton, QuiIcon, QuiIconToken, QuiTag, QuiText, QuiTextInput } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';

export enum ListType {
    Allow,
    Block,
}

type ListOfTagsProps = {
    icon: QuiIconToken;
    title: string;
    regexes: string[];
    piiType?: PiiTypeEnum;
    handleRemoveAllowRegex: (idx: number) => void;
    handleAddRegex: (regex: string) => void;
    handleEditRegex: (idx: number, newRegex: string) => void;
};

export function ListOfRegex({ title, regexes, handleRemoveAllowRegex, handleAddRegex, piiType, handleEditRegex, icon }: ListOfTagsProps) {
    const [regexBeingAdded, setRegexBeingAdded] = useState<string>('');
    const [regexBeingEdited, setRegexBeingEdited] = useState<string>('');
    const [showAddItem, setShowAddItem] = useState<boolean>(false);
    const [idxBeingEdited, setIdxBeingEdited] = useState<number>();

    const fetchStatus = useAtomValue(modifyRequestFetchStatusAtom);

    //if we are making the replay request, clear all edit/add regex state
    useEffect(() => {
        if (fetchStatus === 'loading') {
            setRegexBeingAdded('');
            setShowAddItem(false);
            setIdxBeingEdited(undefined);
            setRegexBeingEdited('');
        }
    }, [fetchStatus]);

    const focusRef = useCallback((node: HTMLInputElement) => {
        node?.focus();
    }, []);

    const handleAdd = () => {
        if (regexBeingAdded !== '' && piiType != null) {
            handleAddRegex(regexBeingAdded);
        }
        setRegexBeingAdded('');
        setShowAddItem(false);
    };

    const handleEdited = (idx: number) => {
        if (piiType == null) return;

        if (regexBeingEdited === '') {
            handleRemoveAllowRegex(idx);
        }

        handleEditRegex(idx, regexBeingEdited);

        setIdxBeingEdited(undefined);
        setRegexBeingEdited('');
    };

    const handleOnKeyUpForAddRegexInput = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleAdd();
        }
        if (e.key === 'Escape') {
            setShowAddItem(false);
            setRegexBeingAdded('');
        }
    };

    return (
        <QuiBox
            padding={'xs'}
            display={'flex'}
            flexDirection={'column'}
            className={styles.listOfTagsContainer}
            borderBottom={'stroke-base'}
            borderRadius={'none'}
        >
            <QuiBox display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <QuiBox display={'flex'} alignItems={'center'} gap={'xs'}>
                    <QuiIcon icon={icon} />
                    <QuiText size={'text-md'}>{title}</QuiText>
                </QuiBox>
                <QuiButton
                    disabled={fetchStatus === 'loading' || showAddItem}
                    size={'sm'}
                    iconLeft={'plus-circle'}
                    onClick={() => setShowAddItem((current) => !current)}
                >
                    Regex
                </QuiButton>
            </QuiBox>
            <QuiBox display={'flex'} gap={'xs'} padding={'sm'} flexDirection={'column'} className={styles.listOfTagsList}>
                {regexes.map((value, idx) => {
                    if (idx === idxBeingEdited) {
                        return (
                            <QuiBox display={'flex'} key={`${value}`} justifyContent={'space-between'} alignItems={'center'}>
                                <QuiTextInput
                                    value={regexBeingEdited}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRegexBeingEdited(e.target.value)}
                                    onKeyPress={(e: KeyboardEvent) => e.key === 'Enter' && handleEdited(idx)}
                                    ref={focusRef}
                                />
                                <QuiBox display={'flex'}>
                                    <QuiButton size={'sm'} iconLeft={'check'} variant={'minimal'} onClick={() => handleEdited(idx)} />
                                    <QuiButton
                                        size={'sm'}
                                        iconLeft={'x'}
                                        variant={'minimal'}
                                        onClick={() => {
                                            setIdxBeingEdited(undefined);
                                            setRegexBeingEdited('');
                                        }}
                                    />
                                </QuiBox>
                            </QuiBox>
                        );
                    }

                    return (
                        <QuiTag className={'fs-mask'} key={idx.toString() + '-' + value}>
                            <QuiBox style={{ width: '100%' }} display={'flex'} justifyContent={'space-between'}>
                                <SmartToolTip fullStoryMask={true} text={value} />
                                <QuiBox display={'flex'}>
                                    <QuiButton
                                        disabled={fetchStatus === 'loading'}
                                        size={'xs'}
                                        variant={'minimal'}
                                        iconLeft={'edit-2'}
                                        onClick={() => {
                                            setShowAddItem(false);
                                            setRegexBeingAdded('');
                                            setRegexBeingEdited(value);
                                            setIdxBeingEdited(idx);
                                        }}
                                    />
                                    <QuiButton
                                        disabled={fetchStatus === 'loading'}
                                        size={'xs'}
                                        variant={'minimal'}
                                        iconLeft={'x'}
                                        onClick={() => handleRemoveAllowRegex(idx)}
                                    />
                                </QuiBox>
                            </QuiBox>
                        </QuiTag>
                    );
                })}
                {showAddItem && (
                    <QuiTextInput
                        className={'fs-mask'}
                        ref={focusRef}
                        value={regexBeingAdded}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRegexBeingAdded(e.target.value)}
                        onKeyUp={handleOnKeyUpForAddRegexInput}
                        iconRight={'corner-down-left'}
                        placeholder={'Enter regex'}
                    />
                )}
            </QuiBox>
        </QuiBox>
    );
}
