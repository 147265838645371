import tableStyles from '@/components/Table/Table.module.scss';
import { useDatasetContext } from '@/contexts/DatasetContext';
import { fetchNerRedactionContextsForPiiType, nerRedactionStatusAtom, useNerRedactionContexts } from '@/stores';
import { PiiTypeEnum } from '@/types';
import { QuiBox, QuiIcon, QuiIconEnum, QuiText } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';
import { Fragment, useEffect, useRef } from 'react';
import { useForm } from 'react-final-form';
import styles from './EntityManagerTable.module.scss';
import EntityManagerTableFilePage from './EntityManagerTableFilePage';

export default function EntityManagerTable({ piiType }: { piiType: string | undefined }) {
    const { dataset } = useDatasetContext();
    const fetchStatus = useAtomValue(nerRedactionStatusAtom);
    const isDataFetching = ['loading', 'refreshing'].includes(fetchStatus);
    const data = useNerRedactionContexts(dataset.id, piiType!);
    const isEmpty = !isDataFetching && !data.filter((row) => row.pages.filter((page) => page.entities.length).length).length;
    const isError = fetchStatus === 'error';

    const form = useForm();
    const formValue = piiType ? form.getState().values.generatorSetup[piiType] : undefined;
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        fetchNerRedactionContextsForPiiType(dataset.id, piiType as PiiTypeEnum);
    }, [dataset.id, piiType, formValue]);

    return (
        <QuiBox
            bg="background-base"
            border="stroke-filled"
            borderRadius="md"
            text="text-sm"
            className={tableStyles.wrapper}
            style={{ height: '100vh' }}
        >
            <table className={tableStyles.table}>
                <colgroup>
                    <col style={{ width: '4%' }} />
                    <col style={{ width: '16%' }} />
                    <col style={{ width: '40%' }} />
                    <col style={{ width: '40%' }} />
                </colgroup>

                <thead style={{ position: 'sticky', top: '0px' }}>
                    <tr>
                        <th>
                            <QuiBox text="text-sm" color="text-subdued" weight={'normal'} style={{ whiteSpace: 'nowrap' }}>
                                File / page
                            </QuiBox>
                        </th>
                        <th>
                            <QuiBox text="text-sm" color="text-subdued" weight={'normal'}>
                                Entity
                            </QuiBox>
                        </th>
                        <th>
                            <QuiBox text="text-sm" color="text-subdued" weight={'normal'}>
                                Context
                            </QuiBox>
                        </th>
                        <th>
                            <QuiBox text="text-sm" color="text-subdued" weight={'normal'}>
                                Transformation
                            </QuiBox>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {isError && (
                        <tr>
                            <td colSpan={4}>
                                <QuiBox padding="xl">
                                    <QuiBox color="text-base" textAlign="center">
                                        <QuiIcon size="xl" color="text-danger" icon={QuiIconEnum.AlertTriangle} />
                                    </QuiBox>

                                    <QuiBox color="text-base" textAlign="center">
                                        Unable to load entities
                                    </QuiBox>
                                </QuiBox>
                            </td>
                        </tr>
                    )}
                    {!isError &&
                        !isEmpty &&
                        !isDataFetching &&
                        data
                            .filter((row) => row.pages.filter((page) => page.entities.length > 0).length > 0)
                            .map((row) => (
                                <Fragment key={`${formValue}-${row.id}-${row.fileName}`}>
                                    <tr>
                                        <td colSpan={4} style={{ borderBottom: '0px' }}>
                                            <QuiText weight="medium">{row.fileName}</QuiText>
                                        </td>
                                    </tr>

                                    <EntityManagerTableFilePage datasetFileId={row.id} piiType={piiType} row={row} />
                                </Fragment>
                            ))}

                    {!isError && isEmpty && (
                        <tr>
                            <td colSpan={4}>
                                <QuiBox padding="xl" color="text-base" textAlign="center">
                                    No entities found
                                </QuiBox>
                            </td>
                        </tr>
                    )}
                    {!isError && isDataFetching && (
                        <tr>
                            <td colSpan={4}>
                                <QuiBox padding="xl">
                                    <QuiBox color="text-base" textAlign="center">
                                        <QuiIcon className={styles.spinning} size="xl" icon={QuiIconEnum.Loader} />
                                    </QuiBox>

                                    <QuiBox color="text-base" textAlign="center">
                                        Loading entities...
                                    </QuiBox>
                                </QuiBox>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </QuiBox>
    );
}
