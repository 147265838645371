import { useDatasetContext } from '@/contexts/DatasetContext';
import usePrevious from '@/hooks/usePrevious';
import { deleteDataset, updateDataset } from '@/stores/datasets';
import { DocXCommentPolicy, DocXImagePolicy, DocXTablePolicy, PDFSignaturePolicy } from '@/types';
import { requiredString } from '@/validation';
import {
    QuiBox,
    QuiButton,
    QuiFlexV,
    QuiForm,
    QuiIconEnum,
    QuiModalContent,
    QuiModalDialog,
    QuiSubmitButton,
    QuiSwitch,
    QuiSwitchField,
    QuiText,
    QuiTextField,
    useQuiToasts,
} from '@tonicai/ui-quinine';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';

export type Props = {
    isOpen: boolean;
    onClose: () => void;
};

export default function DatasetSettingsModal({ isOpen, onClose }: Props) {
    const { dataset } = useDatasetContext();
    const addToast = useQuiToasts();
    const [isEditingName, setIsEditingName] = useState(false);
    const wasEditingName = usePrevious(isEditingName);
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);

    const focusRef = useCallback(
        (node: HTMLInputElement) => {
            if (!wasEditingName && isEditingName) {
                node?.focus();
            }
        },
        [wasEditingName, isEditingName]
    );

    async function submitForm({
        name,
        docXImagePolicy,
        docXCommentPolicy,
        pdfSignaturePolicy,
        docXTablePolicy,
    }: {
        name: string;
        docXImagePolicy: DocXImagePolicy;
        docXCommentPolicy: DocXCommentPolicy;
        pdfSignaturePolicy: PDFSignaturePolicy;
        docXTablePolicy: DocXTablePolicy;
    }) {
        try {
            await updateDataset({
                ...dataset,
                docXImagePolicy,
                docXCommentPolicy,
                pdfSignaturePolicy,
                docXTablePolicy,
                name,
            });

            setIsEditingName(false);
            addToast({
                title: 'Dataset settings saved successfully',
                variant: 'positive',
            });
            onClose();
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.status === 409) {
                addToast({
                    title: "There's already a dataset with that name. Please choose another name.",
                    variant: 'danger',
                });
            } else {
                addToast({
                    title: 'Something unexpected went wrong. Please try again.',
                    variant: 'danger',
                });
            }
        }
    }

    return (
        <QuiModalDialog title="Dataset Settings" className={'modal'} disableDismissOnEscapeKeyDown={false} isOpen={isOpen} onClose={onClose}>
            <QuiModalContent>
                <QuiBox display="flex" gap="md" padding="lg" flexDirection="column">
                    <QuiForm<{
                        name: string;
                        docXImagePolicy: DocXImagePolicy;
                        docXCommentPolicy: DocXCommentPolicy;
                        pdfSignaturePolicy: boolean;
                        docXTablePolicy: DocXTablePolicy;
                    }>
                        initialValues={{
                            name: dataset.name,
                            docXImagePolicy: dataset.docXImagePolicy,
                            docXCommentPolicy: dataset.docXCommentPolicy,
                            pdfSignaturePolicy: dataset.pdfSignaturePolicy === 'Redact',
                            docXTablePolicy: dataset.docXTablePolicy,
                        }}
                        onSubmit={async ({ name, docXImagePolicy, docXCommentPolicy, pdfSignaturePolicy, docXTablePolicy }) => {
                            await submitForm({
                                name,
                                docXImagePolicy,
                                docXCommentPolicy,
                                pdfSignaturePolicy: pdfSignaturePolicy ? 'Redact' : 'Ignore',
                                docXTablePolicy,
                            });
                        }}
                    >
                        <QuiBox flexDirection="column" display="flex" gap="md">
                            <QuiText size="text-sm" weight="medium">
                                Name
                            </QuiText>
                            <QuiBox display="flex" alignItems="center" gap="sm">
                                <QuiTextField
                                    disabled={!isEditingName}
                                    placeholder="Enter the name for the dataset..."
                                    validate={requiredString}
                                    ref={focusRef}
                                    name="name"
                                />

                                {!isEditingName && (
                                    <QuiButton
                                        iconRight={QuiIconEnum.Edit2}
                                        onClick={() => {
                                            setIsEditingName(true);
                                        }}
                                    />
                                )}
                                {isEditingName && (
                                    <>
                                        <FormSpy subscription={{}}>
                                            {({ form }) => {
                                                return (
                                                    <QuiButton
                                                        variant="default"
                                                        iconRight={QuiIconEnum.X}
                                                        onClick={() => {
                                                            form.change('name', dataset.name);
                                                            setIsEditingName(false);
                                                        }}
                                                    />
                                                );
                                            }}
                                        </FormSpy>

                                        <FormSpy subscription={{ values: true }}>
                                            {({ values }) => {
                                                return (
                                                    <QuiButton
                                                        variant="primary"
                                                        iconRight={QuiIconEnum.Check}
                                                        onClick={async () => {
                                                            const { name, docXImagePolicy, docXCommentPolicy, pdfSignaturePolicy, docXTablePolicy } =
                                                                values;
                                                            await submitForm({
                                                                name,
                                                                docXImagePolicy,
                                                                docXCommentPolicy,
                                                                pdfSignaturePolicy: pdfSignaturePolicy ? 'Redact' : 'Ignore',
                                                                docXTablePolicy,
                                                            });
                                                        }}
                                                    />
                                                );
                                            }}
                                        </FormSpy>
                                    </>
                                )}
                            </QuiBox>

                            <QuiBox border="stroke-base" padding="md" text="text-xs">
                                <QuiFlexV gap="xs">
                                    <QuiText size="text-sm">Image settings for DOCX files</QuiText>
                                    <Field name="docXImagePolicy" type="radio" value="Redact">
                                        {({ input }) => (
                                            <label>
                                                <input {...input} type="radio" /> Redact contents of images using OCR
                                            </label>
                                        )}
                                    </Field>

                                    <Field name="docXImagePolicy" type="radio" value="Ignore">
                                        {({ input }) => (
                                            <label>
                                                <input {...input} type="radio" /> Ignore images during scan
                                            </label>
                                        )}
                                    </Field>

                                    <Field name="docXImagePolicy" type="radio" value="Remove">
                                        {({ input }) => (
                                            <label>
                                                <input {...input} type="radio" /> Replace images from the output file with black boxes
                                            </label>
                                        )}
                                    </Field>
                                </QuiFlexV>
                            </QuiBox>

                            <QuiBox border="stroke-base" padding="md" text="text-xs">
                                <QuiFlexV gap="xs">
                                    <QuiText size="text-sm">Table settings for DOCX files</QuiText>
                                    <Field name="docXTablePolicy" type="radio" value="Redact">
                                        {({ input }) => (
                                            <label>
                                                <input {...input} type="radio" /> Redact content using the entity type configuration
                                            </label>
                                        )}
                                    </Field>

                                    <Field name="docXTablePolicy" type="radio" value="Remove">
                                        {({ input }) => (
                                            <label>
                                                <input {...input} type="radio" /> Block out all table cell content
                                            </label>
                                        )}
                                    </Field>
                                </QuiFlexV>
                            </QuiBox>

                            <QuiBox border="stroke-base" padding="md" text="text-xs">
                                <QuiFlexV gap="xs">
                                    <FormSpy subscription={{ values: true }}>
                                        {({ form, values }) => (
                                            <QuiSwitch
                                                label="Remove comments from the output file"
                                                onChange={(val: boolean) => form.change('docXCommentPolicy', val ? 'Remove' : 'Ignore')}
                                                checked={values.docXCommentPolicy === 'Remove'}
                                            />
                                        )}
                                    </FormSpy>
                                </QuiFlexV>
                            </QuiBox>

                            <QuiBox border="stroke-base" padding="md" text="text-xs">
                                <QuiSwitchField name="pdfSignaturePolicy" label="Detect and redact signatures in PDFs" />
                            </QuiBox>

                            <QuiBox display="flex" justifyContent="space-between">
                                <QuiSubmitButton variant="brand-purple">Save Dataset</QuiSubmitButton>
                                <QuiButton
                                    variant="outline-danger"
                                    data-test={isDeleteConfirmed ? 'delete-confirmation' : 'dataset-settings-delete-button'}
                                    onClick={() => {
                                        if (isDeleteConfirmed) {
                                            deleteDataset(dataset.id)
                                                .then(() => {
                                                    addToast({
                                                        title: 'Dataset deleted successfully',
                                                        variant: 'positive',
                                                    });
                                                })
                                                .catch(() => {
                                                    addToast({
                                                        title: 'Dataset could not be deleted successfully',
                                                        variant: 'danger',
                                                    });
                                                })
                                                .finally(() => setIsDeleteConfirmed(false));
                                        } else {
                                            setIsDeleteConfirmed(true);
                                        }
                                    }}
                                >
                                    {isDeleteConfirmed ? 'Confirm Delete' : 'Delete Dataset'}
                                </QuiButton>
                            </QuiBox>
                        </QuiBox>
                    </QuiForm>
                </QuiBox>
            </QuiModalContent>
        </QuiModalDialog>
    );
}
