import { QuiBox, QuiFlexBoxColumn } from '@tonicai/ui-quinine';

export function Welcome() {
    return (
        <QuiFlexBoxColumn gap="lg" padding="md">
            <QuiFlexBoxColumn gap="sm">
                <QuiBox text="display-xs" weight="bold">
                    👋 Welcome!
                </QuiBox>
                <QuiBox text="text-md" color="text-subdued">
                    Textual redacts and synthesizes unstructured data, making it ready for model training, RAG applications, and a wide range of AI
                    workflows. To get a preview of how Textual detects and transforms sensitive values, use the playground tool. Enter your text in
                    the left panel, then view and adjust the results.
                </QuiBox>
            </QuiFlexBoxColumn>
        </QuiFlexBoxColumn>
    );
}
